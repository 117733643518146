import React, { Fragment, Suspense, lazy } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Provider } from 'react-redux'
// import Loader from './components/Loader'
import Notifier from './components/Notifier'
import './sass/main.scss'
import store from './context/store'

// import screens lazyly into application
const Login = lazy(() => import('./screens/login'))
const SignUp = lazy(() => import('./screens/signUp'))
const ForgotPassword = lazy(() => import('./screens/forgotpassword'))
const ResetPassword = lazy(() => import('./screens/resetPassword'))

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading....</div>}>
        <Router>
          <Provider store={store}>
            <Switch>
              <Route
                exact
                path="/"
                component={Login}
                render={() => <Redirect to="/login" />}
              />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
            </Switch>
          </Provider>
          <Notifier />
        </Router>
      </Suspense>
    </>
  )
}

export default App
