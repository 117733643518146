/* eslint-disable no-underscore-dangle */
// dependencies importsf
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'

// file imports
import rootReducer from './rootReducer'
import { loadState, setLocalState } from './localstorage'

const initialState = loadState()

const middleware = applyMiddleware(thunk)

const store = createStore(rootReducer, initialState, middleware)

store.subscribe(
  throttle(() => {
    setLocalState(store.getState())
  }, 1000),
)

export default store
