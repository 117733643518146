import { CREATE_ACCOUNT } from '../constants'
const intialState = {
  user: null,
}

/**
 * Auth reducer to update state
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default function (state = intialState, action) {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}
